import React from 'react';
import { Container, Typography, Box, Button, Grid, Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';

const VideoPage = () => {
    const videos = [
        { id: 'LyAn_H9GPow', title: 'Introduction to SawmillGo', description: 'An introduction to SawmillGo.' },
        { id: 'xjWZJ8T57qc', title: 'Key Features and Project Status', description: 'A brief overview of SawmillGo features.' },
        { id: '1CIxplBe1vs', title: 'QR Code Customer Reports', description: 'An in-depth look into the end reports generated by SawmillGo software.' },
        { id: 'z0eFLkIGESM', title: 'SawmillGo Key Modules', description: 'How SawmillGo tracks logs, planks, and products, ensuring transparency at every stage.' },
        { id: 'ASJldNaVjKo', title: 'Data Transparency & Tracking', description: 'Exploring how SawmillGo supports sustainable practices and generates value for local communities.' },
        { id: 'SgOkXVXPONs', title: 'Creator Login & Diaries', description: 'A look into the system that enables creators to document the creation of the finished product.' },
    ];

    return (
        <Container maxWidth="lg" sx={{ py: 6 }}>
            <Box textAlign="center" mb={6}>
            <Typography variant="h4" align="center" color="primary">
          Demonstration Videos
        </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                    Explore the key elements of SawmillGo through a series of short videos.
                </Typography>
            </Box>
            
            <Grid container spacing={4}>
                {videos.map((video, index) => (
                    <Grid item xs={12} md={6} key={index}>
                        <Card elevation={3} sx={{ borderRadius: 2 }}>
                            <Box
                                component="iframe"
                                width="100%"
                                height="300"
                                src={`https://www.youtube.com/embed/${video.id}`}
                                frameBorder="0"
                                allowFullScreen
                                sx={{
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8
                                }}
                            />
                            <CardContent>
                                <Typography variant="h5" component="h2" gutterBottom>
                                    {index + 1}. {video.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {video.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <Box textAlign="center" mt={8}>
                <Typography variant="h4" gutterBottom>
                    Want to Learn More?
                </Typography>
                <Typography variant="body1" color="text.secondary" paragraph>
                    Reach out to us or get involved to see how you can contribute to the SawmillGo project.
                </Typography>
                <Box mt={3}>
                    <Button variant="contained" color="primary" 
                           component={Link}
                           to="/contact">
                        Contact Us
                    </Button>
                    <Button variant="outlined" color="secondary"        component={Link}
                  to="/investoroverview" sx={{ ml: 2 }}>
                        Get Involved
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default VideoPage;
