import React from 'react';
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { Paper } from '@mui/material';
import AboutSystem from '../components/about-page/AboutSystem';

const FeaturesPage = () => {
 

  return (
    <>
 
    
<Grid container p={5}>
<AboutSystem />
</Grid>

      </>


  );
};

export default FeaturesPage;
